import BaseModel from '../hit/BaseModel';

class Company extends BaseModel {
  constructor({id, name}) {
    super();
    this.id = id;
    this.name = name;
  }
  static factory() {
    return new Company({});
  }
}
export {Company};
